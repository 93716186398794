<template>
  <div>
    <WordSkillForm :formData="formState" @formCancel="handleCancel" @formOk="handleOk" />
  </div>
</template>
<script>

import { editWordSkill, getWordSkill } from '../http/api'
import WordSkillForm from '../components/form/WordSkillForm.vue'
export default {
  components: {
    WordSkillForm
  },
  data() {
    return {
      formState: {},
      id: this.$route.query.id || ''
    }
  },
  created() {
    if (this.id) {
      this.getWordSkill()
    }
  },
  methods: {
    getWordSkill() {
      getWordSkill({variables: {id: this.id}}).then(res => {
        let wordSkill = res.data.wordSkill
        let content = wordSkill.content
        let c = content.replace(/<a[^>]*href=['"]([^"]*)['"][^>]*>(.*?)<\/a>/g, (matchStr, groups, con) => {
          if (con.includes('<img')) {
            return con
          } else {
            return matchStr
          }
        })
        wordSkill.content = c
        this.formState = JSON.parse(JSON.stringify(wordSkill));
      })
    },
    handleOk(e) {
      console.log(e)
      editWordSkill({variables: e}).then(res => {
        console.log(res)
        this.handleCancel()
      })
    },
    handleCancel() {
      this.formState = {}
      this.$router.push('/word-skill')
    }
  },
}
</script>
<style lang="sass" scoped>

</style>